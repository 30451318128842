<template>
  <v-app id="geo-listening">
    <v-main class="bgColorBase wrap-app">
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import { mapActions } from "vuex";
export default {
  created(){
    this.$store.dispatch("global/setDatePickerActive", false);
  },
  methods: {
    ...mapActions("global", ["addTitle", "disabledMenuItem"]),
  }
};
</script>
<style></style>
